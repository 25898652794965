import React, { useEffect, useState } from "react"
import MainLayout from "../components/layouts/main-layout"
import { graphql, Link } from "gatsby"
import HomeHero from "../components/hero/hero"

import Seo from "../components/seo"
import Banner from "../components/hero/banner-hometeam.js"
import PartnerSwiper from "../components/swiper/swiper-partner.js"
import ItConsulting from "../components/it-consulting"
import Testimonials2 from "../components/swiper/testimonial-swiper"
import QuandaryValues from "../components/new-quandary/quandary-services"
import UnlockEnterprise from "../components/unlock-enterprise"
import Cases from "../components/cases"
import CaseStudySection from "../components/header/header-casestudy-section"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const Homepage = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    function f2(ev) {
      ev.preventDefault()
    }

    // A function that invokes a callback when the page has unsaved changes.
    // window.addEventListener("beforeunload", f2)

    // A function that invokes a callback when the page's unsaved changes are resolved.
    // window.removeEventListener("beforeunload", f2)

    // document.body.addEventListener("click", f2, {
    //   capture: false,
    //   passive: true,
    // })
    // document.body.addEventListener("touchstart", f2, {
    //   passive: true,
    //   capture: false,
    // })
  }, [])
  const { data } = props

  return (
    <MainLayout location={props.location} dataSrc={data.allSanityCaseStudy}>
      <Seo
        title="Quandary Consulting Group | Innovation Consultants with LCNC"
        description="We rapidly build applications that break down the tech and process roadblocks keeping your mid-market business from innovating."
      />
      <HomeHero />
      <PartnerSwiper />
      <div id="it-consult" className="it-consult-scroll" />
      <ItConsulting />
      <QuandaryValues />
      <Testimonials2 />
      <CaseStudySection />
      <CaseComponent dataSrc={data.allSanityCaseStudy} />
      <Banner />
      <UnlockEnterprise />
      <section
        className="bg-case-study slogan-section"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center">
              <div>
                <h2 className="text-white">
                  Our 4-Point Innovation Roadmap Saves Firms Up to 30% on
                  Operations and Tech Costs
                </h2>
                {/* <p className="text-white">
                  Our 10-point security audit will show you how secure your
                  applications are. <br /> Protect your data. Protect your
                  investment. Protect your organization.
                </p> */}

                <Link
                  to="/contact/"
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                  style={{ marginTop: "auto" }}
                >
                  Let's Chat
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default Homepage

export const indexQuery = graphql`
  query indexQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
