import React from "react"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const QValues = loadable(() => pMinDelay(import("./values"), 800))

const QuandaryServices = () => {
  return (
    <>
      <section className="benefits-we-bring" style={{ padding: "1.5rem 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center mt-3">
              <h2 className="my-2">Our Other Services</h2>
              {/* <p className="my-4 text-left-mobile">
                We take a consultative approach, working alongside your business
                units to improve operations.
              </p> */}
            </div>
            <div className="col-lg-12 mt-md-5 mt-sm-2 pb-lg-5">
              <div className="text-center">
                <QValues />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default QuandaryServices
